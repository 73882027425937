<template>
  <section class="tour-details-section">
    <div class="row no-gutters p-3">
      <template v-if="$viewport.gt.sm">
        <div class="col-12 col-md-4">
          <div
            v-if="schedule.property_picture_url"
            :style="'background-image: url(' + schedule.property_picture_url + ');'"
            class="property-image-rounded"
          />
          <img
            v-else
            :alt="schedule.property_address"
            :src="DefaultImage"
            class="property-image-rounded"
          >
        </div>
        <div class="col-12 col-md-8">
          <router-link
            :to="{ name: 'app.units.show', params: { id: schedule.property_id, unit_id: schedule.unit_id } }"
            class="font-17 font-weight-bolder"
          >
            {{ schedule.property_address }}
            <span
              v-if="schedule.unit_name"
              class="font-weight-normal"
            > - {{ schedule.unit_name }}</span>
            <i
              v-if="schedule.has_identity_verification"
              class="sdicon-fingerprint text-success mr-1 font-21"
            />
          </router-link>
        </div>
      </template>
      <div
        v-else
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12 col-md-4"
      >
        <h4>Address</h4>
        <span class="tour-detail-span">
          {{ schedule.property_address }}
        </span>
      </div>
    </div>
    <div class="row no-gutters tour-row-upper-border pl-3 pr-3 pt-3">
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12"
      >
        <h4>{{ tourType }} time</h4>
        <div class="d-flex justify-content-between">
          <span class="d-flex tour-detail-span text-gray-darker">
            {{ $momentUtil(schedule.tour_at, propertyTimezone).toDisplayFormat() }}
            <template v-if="schedule.is_open_house">
              <span class="px-1">-</span>
              {{ $momentUtil(schedule.tour_until, propertyTimezone).toDisplayShortTimeFormat() }}
            </template>
          </span>
          <i
            v-if="canBeRescheduled(schedule)"
            class="d-flex sdicon-reschedule reschedule-btn ml-1 font-17"
            @click.stop
            @click="$emit('show-reschedule-dialog', schedule)"
          />
        </div>
      </div>
    </div>
    <div class="row no-gutters pr-3 pl-3 mt-3">
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12"
      >
        <h4>{{ tourType }} agent</h4>
      </div>
    </div>
    <div class="row no-gutters pr-3 pl-3 pb-3 mt-2 align-items-center">
      <div
        v-if="schedule.is_taken_over && schedule.tour_status !== tourConstants.STATUS_CANCELLED_SURRENDERED && schedule.assignee_name"
        :class="$viewport.lt.md ? 'col-10 pr-3 pl-3' : ''"
      >
        <div class="user-tag m-0">
          <img
            v-if="schedule.assignee_avatar"
            :src="schedule.assignee_avatar"
            class="user-avatar-small mr-1"
          >
          <i
            v-else
            class="sdicon-user-tie user-tag-icon"
          />
          <span :class="$viewport.lt.md ? 'font-15' : ''">{{ schedule.assignee_name }}</span>
        </div>
      </div>
      <div
        v-else
        :class="$viewport.lt.md ? 'col-10 pr-3 pl-3' : ''"
      >
        <div class="user-tag bg-gray-lighter m-0">
          <img
            v-if="!schedule.is_self_showing"
            src="@/assets/icon.svg"
            class="user-avatar-small mr-1"
          >
          <span
            class="text-gray-darker"
            :class="$viewport.lt.md ? 'font-15' : ''"
          >
            {{ schedule.is_self_showing ? 'Self Showing' : 'Showdigs' }}
          </span>
        </div>
      </div>
      <div
        v-if="canBeReassigned(schedule)"
        :class="$viewport.lt.md ? 'col ml-2' : 'col ml-2'"
      >
        <i
          class="sdicon-swap reassign-btn pointer font-19"
          @click="$emit('show-reassign-dialog')"
        />
      </div>
    </div>
    <template v-if="schedule.is_video && schedule.meeting_room && schedule.meeting_room.deleted_at === null">
      <div class="row no-gutters pr-3 pl-3 mt-0">
        <div
          :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
          class="col-12"
        >
          <h4>Video tour details</h4>
        </div>
      </div>
      <div class="row no-gutters pr-3 pl-3 pb-3 mt-2 align-items-center">
        <a
          :href="`https://${schedule.meeting_room.url}`"
          target="_blank"
        >
          <ElButton
            size="small"
            type="primary-light"
          >
            <i class="sdicon-video mr-2" />
            <span>Join video meeting</span>
          </ElButton>
        </a>
      </div>
    </template>
    <div
      v-if="leads.general.length"
      class="row no-gutters tour-row-upper-border row pr-3 pl-3 pt-3"
    >
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12"
      >
        <h4>
          {{ $pluralize('Prospect', leads.general.length) }} {{ schedule.is_open_house ? 'that attended' : '' }}
          <span
            v-if="leads.general.length > 1"
            class="text-gray-darker"
          > - {{ leads.general.length }}</span>
        </h4>
      </div>
    </div>
    <div
      v-if="leads.general.length"
      class="row no-gutters tour-row-bottom-border pr-3 pl-3 pb-3 mt-1"
    >
      <div
        v-for="(lead, index) in leads.general"
        :key="index"
        class="w-100 prospect-info"
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
      >
        <TourDetailsLead
          :lead="lead"
          :schedule="schedule"
        />
      </div>
    </div>
    <div
      v-if="leads.canceled.length"
      class="row no-gutters tour-row-upper-border row pr-3 pl-3 pt-3"
    >
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12"
      >
        <h4>
          {{ $pluralize('Prospect', leads.canceled.length) }} that canceled
          <span
            v-if="leads.canceled.length > 1"
            class="text-gray-darker"
          > - {{ leads.canceled.length }}</span>
        </h4>
      </div>
    </div>
    <div
      v-if="leads.canceled.length"
      class="row no-gutters tour-row-bottom-border pr-3 pl-3 pb-3 mt-1"
    >
      <div
        v-for="(lead, index) in leads.canceled"
        :key="index"
        class="w-100 prospect-info"
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
      >
        <TourDetailsLead
          :lead="lead"
          :schedule="schedule"
        />
      </div>
    </div>
    <div
      v-if="canBeCancelled(schedule)"
      class="row no-gutters p-3"
    >
      <ElButton
        class="w-100"
        size="medium"
        type="danger"
        @click.native="$emit('show-cancel-dialog')"
      >
        Cancel {{ tourType }}
      </ElButton>
    </div>
    <div class="row no-gutters p-3 pt-3 pb-3">
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12 col-md-4 d-flex align-items-center"
      >
        <h4>Inquiry time</h4>
      </div>
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12 col-md-8"
      >
        <span class="tour-detail-span">
          {{ $momentUtil(schedule.created_at, propertyTimezone).toDisplayFormat() }}
        </span>
      </div>
    </div>
    <div
      v-if="isFinished(schedule.tour_status)"
      class="row no-gutters p-3 pt-3 pb-3"
    >
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12 d-flex align-items-center"
      >
        <h4>Completion Time</h4>
      </div>
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12"
      >
        <span
          v-if="schedule.is_taken_over"
          class="tour-detail-span"
        >
          {{ $momentUtil(schedule.tour_at, propertyTimezone).toDisplayFormat() }}
        </span>
        <span
          v-else-if="schedule.survey_result"
          class="tour-detail-span"
        >
          {{ $momentUtil(schedule.survey_result.created_at, propertyTimezone).toDisplayFormat() }}
        </span>
        <span
          v-else-if="schedule.timed_out_at"
          class="tour-detail-span"
        >
          {{ $momentUtil(schedule.timed_out_at, propertyTimezone).toDisplayFormat() }}
        </span>
      </div>
    </div>
    <div class="row no-gutters p-3 pt-3 pb-3">
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12 d-flex align-items-center"
      >
        <h4>{{ tourType }} status</h4>
      </div>
      <div
        :class="$viewport.lt.md ? 'pr-3 pl-3' : ''"
        class="col-12"
      >
        <SdStatusBadge :type="schedule.tour_status" />
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from '@vue/composition-api';
import { isFinished } from '@/utils/TourUtil';
import { canBeCancelled, canBeReassigned, canBeRescheduled } from '@/utils/ScheduleUtil';
import { groupLeadsByStatus } from '@/utils/LeadsUtil';
import TourDetailsLead from '@/components/tour/TourDetailsLead.vue';
import tourConstants from '../../constants/Tour';
import DateTime from '../../constants/DateTime';
import DefaultImage from '../../assets/home-icon-in-circle.svg';

export default {
  name: 'TourDetails',
  components: {
    TourDetailsLead,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, context) {
    const propertyTimezone = computed(() => props.schedule.property_timezone);
    const leads = groupLeadsByStatus(props.schedule);
    const tourType = props.schedule.is_open_house ? 'Open House' : 'Tour';

    return {
      tourConstants,
      DefaultImage,
      propertyTimezone,
      DateTime,
      canBeReassigned,
      isFinished,
      canBeCancelled,
      canBeRescheduled,
      leads,
      tourType,
    };
  },
};
</script>

<style lang="scss">
.tour-details-section {
  background: $--color-white;
  border-radius: $app-border-radius;

  .el-table {
    border: 1px solid $--border-color-lighter;
  }

  .user-tag {
    font-size: $--font-size-small;
    padding: 0.25rem;
  }

  .user-avatar-small {
    height: 17px;
    width: 17px;
  }

  h4 {
    font-size: $--font-size-base;
    color: gray-color('dark');
    margin-bottom: 0.25rem;
  }

  .status-canceled-color {
    background: $--color-danger;
  }

  .status-upcoming-color {
    background: $--color-primary;
  }

  .status-successful-color {
    background: $--color-success;
  }

  .property-image-rounded {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    max-width: 80px;
    height: 80px;
  }

  .tour-row-upper-border {
    border-top: 1px solid $--border-color-lighter;
  }

  .tour-row-bottom-border {
    border-bottom: 1px solid $--border-color-lighter;
  }

  @media (max-width: 540px) {
    border: none;
    border-radius: unset;

    .tour-row-upper-border {
      border: none;
    }

    .tour-detail-span {
      font-weight: $font-weight-bold
    }
  }

  .tour-detail-span {
    color: gray-color(darker);
    font-size: $--font-size-medium;
  }

  .prospect-info + .prospect-info {
    margin-top: 1rem;
  }
}
</style>
