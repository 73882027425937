<template>
  <div>
    <h3
      v-if="$viewport.gt.md"
      class="text-gray-darker font-21 font-weight-bolder mt-2"
    >
      {{ tourType }} events
    </h3>
    <div
      v-if="uiFlags.isLoadingActivities"
      class="text-center"
    >
      <ElSpinner
        color="primary"
        :radius="50"
      />
    </div>
    <div class="tour-activities">
      <ElCard
        v-for="(activity, index) in activities.slice(0, paginationCount)"
        :key="`activity-${index}`"
        class="activity-card"
      >
        <UnitActivityTour
          :activity="activity"
          :is-tour-activity="true"
        />
      </ElCard>
      <div
        v-if="activities.length > paginationCount"
        class="show-more hover"
        @click="paginationCount += activitiesPage"
      >
        Show more
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api';

import UnitActivityTour from '../unit/unit-activities/UnitActivityTour';
import { overrideAndformatActivityToPropertyTz } from '../../utils/DatetimeUtil';

const activitiesPage = 5;

export default {
  components: { UnitActivityTour },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    tourType: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({ isLoadingActivities: false });
    const activities = ref([]);
    const paginationCount = ref(activitiesPage);
    watch(() => props.schedule, () => {
      fetchActivities();
    }, { immediate: true });
    return {
      activities, uiFlags, paginationCount, activitiesPage,
    };

    async function fetchActivities() {
      uiFlags.isLoadingActivities = true;
      const rawActivities = await context.root.$store.dispatch('Schedule/getActivities', props.schedule.id);
      rawActivities.forEach((activity) => overrideAndformatActivityToPropertyTz({
        activity,
        activityTimezone: props.schedule.property_timezone,
      }));
      activities.value = rawActivities;
      uiFlags.isLoadingActivities = false;
    }
  },
};
</script>

<style lang="scss">
.tour-activities {
  .show-more {
    color: theme-color('primary');
    font-size: $--font-size-base;
    text-align: center;
    padding: 1rem 0;
    cursor: pointer;
  }

  .activity-card {
    margin-bottom: 2px;
    border: none;

    @media (max-width: 540px) {
      box-shadow: 0px 0px 15px rgba(13, 136, 220, 0.14);
      margin-top: 1rem;
      border: none;
    }

    .el-card__body {
      padding: 1rem;
      position: relative;

      @media (max-width: 540px) {
        padding: 1rem;
      }
    }

    .activity-date {
      position: absolute;
      top: 1rem;
      right: 1rem;
      line-height: 1.5rem;
      font-size: $--font-size-extra-small;
      color: gray-color();
    }

    .activity-icon {
      border-radius: 50%;
      border-color: gray-color('light');
      border-width: 1px;
      border-style: solid;
      width: 40px;
      height: 40px;
      text-align: center;

      i {
        line-height: 40px;
        font-size: 24px;
        color: gray-color('dark');

        @media (max-width: 540px) {
          font-size: $--font-size-base;
          line-height: unset;
        }
      }

      @media (max-width: 540px) {
        width: 24px;
        height: 24px;
      }
    }

    h3 {
      font-size: $--font-size-medium;
      width: 75%;
      color: gray-color('darker');
      font-weight: $strong;
      line-height: 1.5rem;
      margin-bottom: 0;

      span.activity-id {
        color: gray-color('dark');
      }
    }

    .activity-data {
      font-size: $--font-size-small;
      display: flex;
      color: gray-color('darker');
      margin-bottom: 0.5rem;
      line-height: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        width: 5rem;
        color: gray-color('dark')
      }
    }

    .activity-comments {
      border-left: 1px solid gray-color('dark');
      padding: 0 0.5rem;
      font-size: $--font-size-small;
      color: gray-color('darker');
    }

    .activity-summary {
      font-size: $--font-size-base;
      color: gray-color('darker');
      line-height: 1rem;
      margin-bottom: 1rem;
      margin-top: 0.2rem;
    }
  }
}
</style>
