<template>
  <div>
    <section
      v-if="!uiFlags.showSubmissionForm"
      class="tour-notes-empty-state"
    >
      <img src="@/assets/note.svg">
      <ElButton
        type="primary"
        size="small"
        class="w-25 align-self-center mt-5"
        @click="uiFlags.showSubmissionForm = true"
      >
        Review {{ tourType }}
      </ElButton>
    </section>
    <section
      v-else
      class="tour-review-section p-3"
    >
      <ElForm
        ref="form"
        :validate-on-rule-change="false"
        :model="finalPayloadRef"
        @submit.native.prevent="submit"
      >
        <div
          v-for="(field, fieldIndex) in surveyJSONRef"
          :key="fieldIndex"
          :class="passCondition(field) ? 'row pt-3 pr-3 pl-3' : ''"
        >
          <template v-if="field.inquiry_specific">
            <div
              v-for="leadRelatedInfo in schedule.leads"
              :key="leadRelatedInfo.schedule_inquiry_id"
              class="col-12"
            >
              <div v-if="passCondition(field)">
                <TourReviewSubmissionFormField
                  :key="fieldIndex"
                  :field="field"
                  :lead-related-info="leadRelatedInfo"
                  :payload-value="finalPayloadRef[field.key][leadRelatedInfo.schedule_inquiry_id]"
                  @review-field-changed="updateReviewPayload"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="passCondition(field)"
              class="col-12"
            >
              <TourReviewSubmissionFormField
                :key="fieldIndex"
                :field="field"
                :payload-value="finalPayloadRef[field.key]"
                @review-field-changed="updateReviewPayload"
              />
            </div>
          </template>
        </div>
        <div class="col-12 pt-5">
          <ElButton
            :class="$viewport.gt.md ? '' : 'w-100'"
            :loading="uiFlags.formIsLoading"
            type="primary"
            size="medium"
            @click.prevent="submit"
          >
            Submit
          </ElButton>
        </div>
      </ElForm>
    </section>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';

import { showErrorNotify, showSuccessNotify } from '../../utils/NotifyUtil';
import TourReviewSubmissionFormField from './TourReviewSubmissionFormField';

export default {
  name: 'TourReviewSubmissionForm',
  components: {
    TourReviewSubmissionFormField,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {
      },
    },
    tourType: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const surveyJSONRef = ref({});
    const finalPayloadRef = ref({});
    const uiFlags = reactive({
      showSubmissionForm: false,
      formIsLoading: false,
    });

    onMounted(async () => {
      const rawReviewFields = await context.root.$store.dispatch('Tour/getTourSurvey', props.schedule.tour_id);
      prepareSurveyFieldsAndPayload(rawReviewFields.fields);
    });
    return {
      uiFlags,
      surveyJSONRef,
      passCondition,
      finalPayloadRef,
      updateReviewPayload,
      submit,
    };

    async function submit() {
      try {
        uiFlags.formIsLoading = true;
        await context.refs.form.validate();
        const preparedPayload = preparePayload();
        await context.root.$store.dispatch('Tour/postTourReview', {
          id: props.schedule.tour_id,
          payload: preparedPayload,
        });
        showSuccessNotify(context, 'Tour review was submitted');
        context.emit('submitted');
        uiFlags.formIsLoading = false;
      } catch (error) {
        uiFlags.formIsLoading = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    function updateReviewPayload(data) {
      if (data.inquiryKey) {
        finalPayloadRef.value[data.fieldKey][data.inquiryKey] = data.fieldValue;
      } else {
        finalPayloadRef.value[data.fieldKey] = data.fieldValue;
      }
    }

    function passCondition(field) {
      /* eslint-disable array-callback-return, consistent-return */
      if (typeof field.conditions !== 'undefined') {
        return field.conditions.every(({
          field: fieldName, operator, value, values,
        }) => {
          if (typeof operator === 'undefined') {
            operator = 'equal';
          }
          if (operator === 'equal') {
            if (value) {
              // eslint-disable-next-line eqeqeq
              return finalPayloadRef.value[fieldName] == value;
            }
            if (values) {
              return values.indexOf(finalPayloadRef.value[fieldName]) !== -1;
            }
          }
        });
      }
      /* eslint-enable array-callback-return, consistent-return */
      return true;
    }

    function preparePayload() {
      const payload = {
        ...finalPayloadRef.value,
      };
      for (const fieldKey in payload) {
        const field = surveyJSONRef.value.find((field) => field.key === fieldKey);
        if (!passCondition(field)) {
          delete payload[fieldKey];
        }
      }

      return payload;
    }

    function prepareSurveyFieldsAndPayload(rawReviewFields) {
      rawReviewFields.forEach((field, fieldIndex) => {
        if (field.type === 'radio') {
          field.options.forEach((radioFieldOption, radioFieldOptionIndex) => {
            if (radioFieldOption.concurrent_tours_only === true && props.schedule.leads.length === 1) {
              rawReviewFields[fieldIndex].options.splice(radioFieldOptionIndex, 1);
            }
          });
        }
        if (field.inquiry_specific) {
          finalPayloadRef.value[field.key] = {};
          props.schedule.leads.forEach((leadRelatedData) => {
            let defaultFieldValue = field.default || null;
            if (field.type === 'radio') {
              defaultFieldValue = field.options[0].value;
            }
            finalPayloadRef.value[field.key][leadRelatedData.schedule_inquiry_id] = defaultFieldValue;
          });
        } else {
          finalPayloadRef.value[field.key] = field.default || null;
        }
      });
      surveyJSONRef.value = rawReviewFields;

      finalPayloadRef.value = {
        ...finalPayloadRef.value,
      };
    }
  },
};
</script>

<style lang="scss">
    .tour-review-section .col:nth-child(n+2) {
        border-top: 1px solid gray-color(light);
    }

    .tour-review-section {
      background: $--color-white;
      border-radius: $app-border-radius;
      white-space: break-spaces;

      .el-radio__input.is-checked + .el-radio__label {
        color: inherit;
      }

      @media (max-width: 540px) {
        border: none;
        border-radius: unset;
        .row.bg-gray-lighter:last-child {
          border-radius: unset;
        }
        }

        h3 {
            margin-bottom: 0;
        }
    }
</style>
