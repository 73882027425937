<template>
  <div class="tour-review">
    <section>
      <h4>{{ tourType }} outcome</h4>
      <slot />
    </section>
    <section v-if="applicationResults.length">
      <div
        v-for="(applicationResult, index) in applicationResults"
        :key="index"
        class="outcome"
      >
        <h4 class="capitalize">
          <i class="dot bg-gray-dark" /> Prospect -
          <ProspectProfileLink :prospect="applicationResult" />
        </h4>
        <div>{{ applicationResult.answer }}</div>
      </div>
    </section>
    <section
      v-for="(result, index) in results"
      :key="index"
      class="result"
    >
      <h4>{{ result.question }}</h4>
      <div
        class="answer"
        :class="result.className"
      >
        <span
          v-for="(answer, ansIndex) in result.answers"
          :key="answer.text + answer.name"
        >
          <strong v-if="answer.name">{{ answer.name }}: </strong>
          {{ answer.text }}{{ result.answers.length - 1 > ansIndex ? ', ' : '' }}
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import SurveyResult from '@/constants/SurveyResult';
import groupBy from 'lodash.groupby';
import ProspectProfileLink from '../prospect/ProspectProfileLink.vue';

export default {
  name: 'TourReview',
  components: { ProspectProfileLink },
  props: {
    prospects: {
      type: Array,
      required: true,
    },
    surveyResults: {
      type: Array,
      required: true,
    },
    tourType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const applicationResults = computed(computeApplicationResults);
    const results = computed(computeResults);
    return {
      applicationResults,
      results,
    };
    function computeApplicationResults() {
      const applications = [];
      props.prospects.forEach((prospect) => {
        const scheduleInquiryId = prospect.schedule_inquiry_id;
        const prospectOutcome = props.surveyResults
          .find((result) => result.key === SurveyResult.apply.key && result.schedule_inquiry_id === scheduleInquiryId);
        if (prospectOutcome) {
          applications.push({
            id: prospect.id,
            lead_name: prospect.full_name,
            name: prospect.full_name,
            answer: !Number.isInteger(prospectOutcome.answer)
              ? (SurveyResult.apply.answers[prospectOutcome.answer.toLowerCase()] || prospectOutcome.answer)
              : `How much did they like the property: ${prospectOutcome.answer}/5`,
          });
        }
      });
      return applications;
    }
    function computeResults() {
      const prospectsByScheduleInquiry = groupBy(props.prospects, 'schedule_inquiry_id');
      const prospectsNames = props.prospects.map((prospect) => prospect.full_name);
      const filtered = props.surveyResults.filter((result) => ![SurveyResult.outcome.key, SurveyResult.apply.key].includes(result.key));
      const groupedByResultKey = groupBy(filtered, 'key');
      return Object.keys(groupedByResultKey).map((resultKey) => {
        const keyResults = groupedByResultKey[resultKey];
        const answers = keyResults.map((result) => {
          const prospectName = result.schedule_inquiry_id ? prospectsByScheduleInquiry[result.schedule_inquiry_id][0].full_name : null;
          return { name: prospectName, text: result.answer || 'No answer provided' };
        });
        return {
          question: cleanProspectNameFromQuestion(prospectsNames, keyResults[0].question),
          answers,
          className: answers.length === 1 && !answers[0].text ? 'text-danger' : '',
        };
      });
    }
    function cleanProspectNameFromQuestion(prospectNames, question) {
      let cleanQuestion = question;
      prospectNames.forEach((name) => {
        cleanQuestion = cleanQuestion.replace(name, 'prospect');
      });
      return cleanQuestion;
    }
  },
};
</script>

<style lang="scss" scoped>
.tour-review {
  background: $white;
  border-radius: $app-border-radius;
  section {
    border-bottom: 1px solid gray-color('light');
    padding: 1rem;
    white-space: break-spaces;
  }
  h4 {
    font-size: $--font-size-base;
    color: gray-color('dark');
    margin-bottom: 0.25rem;
    i {
      color: gray-color('dark');
      width: 5px;
      height: 5px;
      margin: 3px 0.5rem 3px 0;
    }
  }
  .answer {
    font-size: $--font-size-base;
  }
  .outcome {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    div {
      font-size: $--font-size-base;
      margin-left: 1rem;
    }
  }
}
</style>
