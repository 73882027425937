// we only use `required` validation right now
function getSurveyFieldValidationRules(field) {
  return { required: field.validations && field.validations[0] === 'required', message: 'Field is required' };
}
function renderSurveyFieldLabel(label, leadRelatedData = null) {
  if (leadRelatedData) {
    return label.replace(/{lead_name}/g, leadRelatedData.full_name);
  }
  return label;
}

export { getSurveyFieldValidationRules, renderSurveyFieldLabel };
