<template>
  <div>
    <p class="text-gray-darker font-weight-strong">
      {{ renderSurveyFieldLabel(field.label,leadRelatedInfo) }}
    </p>
    <small v-if="field.helper">{{ field.helper }}</small>
    <ElFormItem
      :prop="leadRelatedInfo ? `${field.key}.${leadRelatedInfo.schedule_inquiry_id}` : `${field.key}`"
      :rules="getSurveyFieldValidationRules(field)"
    >
      <!-- Textarea -->
      <ElInput
        v-if="field.type === 'textarea'"
        v-model="payloadValueRef"
        type="textarea"
        :rows="4"
        placeholder="Type your answer"
        @input="emitReviewFieldChanged"
      />

      <!-- Select -->
      <ElSelect
        v-else-if="field.type === 'select'"
        v-model="payloadValueRef"
        placeholder="Select your answer"
        @input="emitReviewFieldChanged"
      >
        <ElOption
          v-for="(option, optionIndex) in field.options"
          :key="optionIndex"
          :label="option.label"
          :value="option.value"
        />
      </ElSelect>

      <!-- Radio -->
      <ElRadioGroup
        v-if="field.type === 'radio'"
        v-model="payloadValueRef"
        class="row mt-0"
        @change="emitReviewFieldChanged"
      >
        <ElRadio
          v-for="(option, optionIndex) in field.options"
          :key="optionIndex"
          class="col-12 pt-0"
          :label="option.value"
        >
          {{ option.label }}
        </ElRadio>
      </ElRadioGroup>
    </ElFormItem>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import { getSurveyFieldValidationRules, renderSurveyFieldLabel } from '../../utils/SurveyUtil';

export default {
  name: 'TourReviewSubmissionFormField',
  props: {
    payloadValue: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    leadRelatedInfo: {
      type: Object,
      default: () => null,
    },
  },
  setup(props, context) {
    const payloadValueRef = ref(props.payloadValue || null);
    return {
      payloadValueRef,
      renderSurveyFieldLabel,
      getSurveyFieldValidationRules,
      emitReviewFieldChanged,
    };

    function emitReviewFieldChanged() {
      const data = {
        fieldValue: payloadValueRef.value,
        fieldKey: props.field.key,
        inquiryKey: props.leadRelatedInfo ? props.leadRelatedInfo.schedule_inquiry_id : null,
      };
      context.emit('review-field-changed', data);
    }
  },
};
</script>

<style lang="scss">
</style>
